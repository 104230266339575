import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Input, PasswordInput } from "@ehabitation/ui";
import {
  EmailAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import config from "config";
import { auth } from "firebaseConfig";
import { useIsMounted } from "hooks";
import { logLoginEvent } from "helpers/analytics";
import { FaSpinner } from "react-icons/fa";

interface Tenant {
  value: string;
  label: string;
  tenantId: string;
  microsoftTenantId: string;
}

const tenants: Tenant[] = [
  {
    value: "",
    label: "Select an enterprise login",
    tenantId: "",
    microsoftTenantId: "",
  },
  {
    value: "default",
    label: "Default EHAB login",
    tenantId: "",
    microsoftTenantId: "",
  },
  {
    value: "Kier",
    label: "Kier",
    tenantId: config.KIER_TENANT_ID,
    microsoftTenantId: "d8de327a-9836-443f-8bbc-a1c10ff08dc0",
  },
  {
    value: "Morgan Sindall",
    label: "Morgan Sindall",
    tenantId: config.MORGAN_SINDAL_TENENT_ID,
    microsoftTenantId: "71f4f5a1-3263-48ad-8ea4-0981a6fe99f4",
  },
  {
    value: "EHAB SSO",
    label: "EHAB SSO",
    tenantId: config.EHAB_TENENT_ID,
    microsoftTenantId: "1ff0ab2c-c989-4729-a188-228acea7b11b",
  },
];

interface TenantSettings {
  tenantId: string | null;
  microsoftTenantId: string | null;
}

const defaultSettings: TenantSettings = {
  tenantId: null,
  microsoftTenantId: null,
};

const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const useLogin = (reauthenticate: boolean) => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailField, setEmailField] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [mfChallenge, setMfChallenge] = useState(false);
  const [mfResolver, setMfResolver] = useState<MultiFactorResolver | null>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const [selectedTenant, setSelectedTenant] = useState("");
  const [tenantSettings, setTenantSettings] =
    useState<TenantSettings>(defaultSettings);

  const loginMicrosoft = async (tenantSettings: TenantSettings) => {
    setIsLoading(true);
    try {
      var provider = new OAuthProvider("microsoft.com");
      if (tenantSettings.tenantId) {
        auth.tenantId = tenantSettings.tenantId;
      }
      if (tenantSettings.microsoftTenantId) {
        provider.setCustomParameters({
          tenant: tenantSettings.microsoftTenantId,
        });
      }
      // Use signInWithPopup and handle the result
      await signInWithPopup(auth, provider);
      // Keep the spinner spinning on successful login
      // logLoginEvent();
      // if (isMounted()) {
      //   navigate("/domain-select");
      // }
    } catch (e) {
      console.error("Microsoft sign-in failed:", e);
      // Stop the spinner on failure
      setIsLoading(false);
      // Optionally, set error state here
    }
    // No need for finally block to stop spinner, handled in catch
  };

  useEffect(() => {
    if (selectedTenant && selectedTenant !== "default") {
      const selectedTenantSettings = tenants.find(
        (tenant) => tenant.value === selectedTenant
      );
      if (selectedTenantSettings) {
        setTenantSettings({
          tenantId: selectedTenantSettings.tenantId,
          microsoftTenantId: selectedTenantSettings.microsoftTenantId,
        });
      }
    } else {
      setTenantSettings(defaultSettings);
      if (selectedTenant === "default") {
        setSelectedTenant("");
      }
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (mfResolver && !recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
        size: "invisible",
      });
      const mfInfo = mfResolver.hints.find(
        (f) => f.factorId === PhoneMultiFactorGenerator.FACTOR_ID
      );
      const phoneInfoOptions = {
        multiFactorHint: mfInfo,
        session: mfResolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, verifier!)
        .then(function (verificationId) {
          setVerificationId(verificationId);
        });
      setRecaptchaVerifier(verifier);
    }
  }, [mfResolver]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setError("");
    if (e.target.name === "email") setEmailField(e.target.value);
    if (e.target.name === "password") setPassword(e.target.value);
    if (e.target.name === "mfaCode") setMfaCode(e.target.value);
    if (e.target.name === "tenant") setSelectedTenant(e.target.value);
  };

  const isValid = () => {
    if (!emailField || !password) return false;
    return true;
  };

  const verifyCode = async () => {
    const cred = PhoneAuthProvider.credential(verificationId, mfaCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await mfResolver?.resolveSignIn(multiFactorAssertion);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e.currentTarget.name === "mfa-code") {
      await verifyCode();
    } else {
      await handleLogin(e);
    }
  };

  const handleLogin = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!isValid()) return setError("Please provide an email and password");

    setError("");
    setIsLoading(true);

    try {
      if (reauthenticate) {
        await reauthenticateWithCredential(
          auth.currentUser!,
          EmailAuthProvider.credential(emailField, password)
        );
      } else {
        await login(emailField, password);
      }
      logLoginEvent();
      if (isMounted()) {
        navigate("/domain-select");
      }
    } catch (e) {
      const error = e as { code: string };
      if (isMounted()) {
        setIsLoading(false);
        if (error.code == "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(
            auth,
            error as MultiFactorError
          );
          setMfResolver(resolver);
          setMfChallenge(true);
        } else if (
          [
            "auth/user-not-found",
            "auth/wrong-password",
            "auth/invalid-email",
          ].includes(error?.code)
        ) {
          setError("Incorrect email or password.");
        } else {
          console.error(error);
          setError(
            "Login failed, please try again or contact support@ehab.co if the error persists."
          );
        }
      }
    }
  };

  return {
    email: emailField,
    password,
    error,
    isLoading,
    handleChange,
    handleSubmit,
    mfChallenge,
    verifyCode,
    selectedTenant,
    tenantSettings,
    loginMicrosoft,
  };
};

const LoginForm = ({ reauthenticate }: { reauthenticate: boolean }) => {
  const {
    email,
    password,
    error,
    handleChange,
    handleSubmit,
    isLoading,
    mfChallenge,
    verifyCode,
    selectedTenant,
    tenantSettings,
    loginMicrosoft,
  } = useLogin(reauthenticate);

  return (
    <>
      <div id="mfa-captcha"></div>

      {mfChallenge && (
        <>
          <Input
            className="mb-8 self-stretch"
            type="text"
            onChange={handleChange}
            placeholder="SMS Code"
            name="mfaCode"
            aria-label="SMS Code"
            disabled={isLoading}
          />
          <Button
            id="verify"
            className="my-4 flex items-center justify-center"
            type="button"
            tabIndex={1}
            onClick={verifyCode}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Validate code
                <FaSpinner className="ml-2 animate-spin" />
              </>
            ) : (
              "Validate code"
            )}
          </Button>
          <span>Or</span>
        </>
      )}

      <select
        className="w-full text-[1.6rem] sm:text-[1.4rem] min-w-[24rem] outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89] bg-[url('/src/assets/icons/chevron.svg')] bg-[right_-20px_top_50%] bg-no-repeat bg-[9rem_auto,100%] rounded-full border border-[#444444] border-solid py-4 pl-12 pr-14 appearance-none"
        value={selectedTenant || ""}
        onChange={handleChange}
        name="tenant"
        aria-label="Select Tenant"
        disabled={isLoading}
      >
        {tenants.map((tenant) => (
          <option key={tenant.value} value={tenant.value}>
            {tenant.label}
          </option>
        ))}
      </select>

      {selectedTenant && (
        <>
          <Button
            id="sign-in-microsoft"
            className="mb-8 flex items-center justify-center"
            type="button"
            tabIndex={1}
            onClick={() => loginMicrosoft(tenantSettings)}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Signing in...
                <FaSpinner className="ml-2 animate-spin" />
              </>
            ) : (
              "Sign in with Microsoft"
            )}
          </Button>
        </>
      )}

      {!selectedTenant && !mfChallenge && (
        <>
          <span>Or</span>
          <form
            id="login-form"
            name={"login-form"}
            onSubmit={handleSubmit}
            className="flex flex-col items-center w-full max-w-xl"
            aria-label="Login"
          >
            <Input
              className="mb-8 self-stretch"
              value={email}
              onChange={handleChange}
              type="email"
              autoComplete="email"
              placeholder="Email"
              tabIndex={1}
              name="email"
              aria-label="Email"
              disabled={isLoading}
            />
            <PasswordInput
              className="self-stretch"
              value={password}
              autoComplete="current-password"
              onChange={handleChange}
              placeholder="Password"
              tabIndex={1}
              name="password"
              aria-label="Password"
              disabled={isLoading}
            />

            <Link to="/reset_password" className="text-blue-500 mt-2">
              Forgot your password?
            </Link>

            {error && (
              <p className="pt-4 text-red-700 text-center">{error}</p>
            )}

            <Button
              id="sign-in"
              className="my-4 flex items-center justify-center"
              type="submit"
              tabIndex={1}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Submitting...
                  <FaSpinner className="ml-2 animate-spin" />
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </form>

          <span>Or</span>
          <Link to="/register" className="text-blue-500 text-2xl mt-2">
            Create Account
          </Link>
        </>
      )}
    </>
  );
};

export default LoginForm;
