import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "ui-shadcn/tooltip";

interface WeeklyActivities {
  [week: number]: number; // The key is the week number, and the value is the activity count for that week
}

interface SingleRowActivityCalendarProps {
  title: string;
  data: number[];
}

export const SingleRowHeatmap = ({
  title,
  data,
}: SingleRowActivityCalendarProps) => {
  const squareWidthPercent = data ? 100 / data.length : 0;

  const getColorForSuccessRatio = (ratio: number): string => {
    if (ratio >= 0.8) return "bg-red-600";
    if (ratio >= 0.6) return "bg-red-200";
    if (ratio >= 0.4) return "bg-yellow-400";
    if (ratio >= 0.2) return "bg-green-200";
    if (ratio >= 0.0) return "bg-green-400";
    return "bg-gray-300";
  };

  const getMonthLabel = (index: number) => {
    const monthLabels: Record<number, string> = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };
    return monthLabels[index] || ""; // Return the label or an empty string if not found
  };

  return (
    <>
      <div className="items-start gap-6 rounded-lg px-4 pt-2">
        <div className="flex-shrink-0 text-left text-xs">{title}</div>

        <div className="flex flex-grow">
          {data &&
            data.map((monthlyRate, i) => {
              const probOfFailure = 1 - monthlyRate;
              return (
                <TooltipProvider key={i}>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <div
                        style={{
                          width: `${squareWidthPercent}%`,
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "2px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "14px",
                          }}
                          className={getColorForSuccessRatio(probOfFailure)}
                        />
                        {[0, 2, 5, 8, 11].includes(i) && (
                          <div className="text-xs mt-0.5">
                            {getMonthLabel(i)}
                          </div>
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        {(probOfFailure * 100).toFixed(1)}% prob. of impact{" "}
                        {getMonthLabel(i)}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
        </div>
      </div>
    </>
  );
};
