import { useCallback } from "react";
import { ITaskCategory } from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers";
import { ISiteHeatmapData } from "Components/Settings/TaskCategories/CategoryHeatmaps/SingleCategoryHeatmap";

export const useFetchHighRiskCategories = (siteId: string) => {
    const fetchHighRiskCategories = useCallback(async (categories: ITaskCategory[]): Promise<ITaskCategory[]> => {
      const fetchCategorySuccessRatio = async (category: ITaskCategory) => {
        try {
          const generateSiteHeatmap = firebaseFunction("GenerateSiteHeatmap");    
          const response = await generateSiteHeatmap({
            site: siteId,
            riskmatrix: { [category.name]: category },
          });
    
          const heatmap = (response.data as ISiteHeatmapData)?.heatmap;
          if (heatmap && typeof heatmap === 'object') {
            const categoryData = heatmap[category.name as keyof typeof heatmap];
            if (categoryData && typeof categoryData === 'object' && 'combined_success_ratio' in categoryData) {
              return categoryData.combined_success_ratio;
            }
          }
        } catch (error) {
          console.error("Error fetching heatmap", error);
        }
        return [];
      };
  
      const categoriesWithHighRisk: ITaskCategory[] = [];
      for (const category of categories) {
        const ratios = await fetchCategorySuccessRatio(category);
        if (ratios.every(ratio => typeof ratio === 'number' && ratio < 0.1)) {
          categoriesWithHighRisk.push(category);
        }
      }
      return categoriesWithHighRisk;
    }, [siteId]);
  
    return { fetchHighRiskCategories };
  };